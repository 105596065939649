/** +++___+++ 1 **/
import Dexie from "dexie";
import { TScans } from "./types.ts";

/**
 * https://dexie.org/docs/Version/Version.stores() :
 * - Unlike SQL, you don’t need to specify all properties but only the one you wish to index.
 *   ++     Auto-incremented primary key
 *   &      Unique index
 *   *      Multi-entry index (Means that if key is an array, each array value will be regarded as a key to the object)
 *   [A+B]  Compound index or primary key
 *
 * Only properties of certain types can be indexed. This includes string, number, Date and Array
 * but NOT boolean, null or undefined. Indexing a property path that turns out to hold a non-indexable
 * type will have no effect. And using orderBy() with that property will NOT list that object.
 */
// db.version(1).stores({
//    familles: '&code',     // 1st = Primary key (leave empty for no prim key), others = indexed props
//    articles: '&code',     // 1st = Primary key (leave empty for no prim key), others = indexed props
// })

export class JackDB extends Dexie {
   // Declare implicit table properties.
   // (just to inform Typescript. Instantiated by Dexie in stores() method)
   scans!: Dexie.Table<TScans, string>; // string = type of the primkey
   //...other tables goes here...

   constructor() {
      super("jack");
      this.version(5).stores({
         /** +++___+++ 3 **/
         scans: "&name",
         //...other tables goes here...
      });
   }
}

export const db = new JackDB();

export const cleanDB = async () => {
   console.log("CleanDB", db);
   await db.delete({ disableAutoOpen: false });
};
