import { PapooButton } from "@/components/papoo/PapooButton";
// import { Html5Qrcode, CameraDevice } from "html5-qrcode";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./Scanner.module.scss";
import useHelpers from "@/Hooks/useHelpers";
import useSound from "use-sound"; // Si pb pour trouver TS types, voir l'astuce dans dossier `generated-types` de ce projet
import blip_snd from "@/assets/sounds/blip.mp3";
import error_snd from "@/assets/sounds/error.mp3";
import key_snd from "@/assets/sounds/key.mp3";
import {
   faDeleteLeft,
   faCheck,
   faBarcodeRead,
   faPen,
   faTrash,
} from "@fortawesome/pro-solid-svg-icons";

// import filesaver from "file-saver";
import moment from "moment";
import fileDownload from "js-file-download";
import { cn } from "@/lib/utils";
import { db as dexie_db } from "@/stores/db";
import { TArticle } from "@/stores/types";
import { PapooInput, PapooInputField } from "@/components/papoo/PapooInput";

const normalize_len = 5;

function Scanner() {
   const { formatNumber, normalizeCode } = useHelpers();
   const navigate = useNavigate();
   const [prev_qty, setPrevQty] = useState<number>(0); // Si on a déjà scanné le code
   // const [got_existing_scan, setGotExistingScan] = useState<boolean>(false);
   const [scanned_articles, setScannedArticles] = useState<TArticle[]>([]);
   const [is_scanner_visible, setIsScannerVisible] = useState<boolean>(true);
   const [is_keypad_visible, setIsKeypadVisible] = useState<boolean>(false);
   const [cur_code, setCurCode] = useState<string>();
   const [scan_val, setScanVal] = useState<string>();
   const [tmp_scan_val, setTmpScanVal] = useState<string>(""); // Le scan en cours de saisie (se termine quand saisie \n)
   const [qty, setQty] = useState<number>(0);
   const [, setUsedPreValue] = useState<boolean>(false); // Si on a utilisé un bouton "pré-rempli"
   const [is_replace_mode, setIsReplaceMode] = useState<boolean>(false); // Si true, on est en édition d'une quantité (clic sur le crayon), on n'affiche donc pas l'ancienne valeur et on ne fait pas la somme, on remplace
   const [is_real_input_field_visible, setIsRealInputFieldVisible] =
      useState<boolean>(false); // Affichage d'un champ de saisie au clavier ?
   const [play_blip] = useSound(blip_snd);
   const [play_error] = useSound(error_snd);
   const [play_key] = useSound(key_snd);
   const ref_scan_input = useRef<HTMLInputElement>(null);

   useEffect(() => {
      dexie_db.scans.get("scans").then((record) => {
         if (record) {
            try {
               setScannedArticles(
                  (JSON.parse(record.scans) as TArticle[]) ?? [],
               );
            } catch (err) {
               setScannedArticles([]);
            }
         } else {
            setScannedArticles([]);
         }
      });
   }, [normalizeCode]);

   const showScanner = useCallback((show: boolean) => {
      if (show) {
         setScanVal("");
         setTmpScanVal("");
      }
      setIsKeypadVisible(!show); // Montrer ou masquer le scanner ne provoque pas d'aff du keypad. Utiliser showKeypad() à la place
      setIsScannerVisible(show);
   }, []);

   const showKeypad = useCallback(
      (show: boolean, code?: string) => {
         setIsReplaceMode(code !== undefined);
         if (code !== undefined) {
            setScanVal(normalizeCode(code, normalize_len));
            setIsKeypadVisible(show);
            setIsScannerVisible(!show);
            setQty(0);
         } else {
            if (tmp_scan_val?.length) {
               setIsKeypadVisible(show);
               setIsScannerVisible(!show);
               if (show) {
                  setScanVal(normalizeCode(tmp_scan_val, normalize_len));
               }
               setQty(0);
            }
         }
      },
      [normalizeCode, tmp_scan_val],
   );

   useEffect(() => {
      console.log(scan_val);
      if (scan_val?.length) {
         const found = scanned_articles.find(
            (a_scan) => a_scan.code === scan_val,
         );
         setCurCode(scan_val);

         if (!found) {
            play_blip();
            setPrevQty(0);
            // setGotExistingScan(false);
            // setTimeout(() => setGotNewScan(false), 100);
            // showKeypad(true);
            setQty(0);
         } else {
            play_error();
            // setGotExistingScan(true);
            setPrevQty(found.qty);
            // setTimeout(() => setGotExistingScan(false), 100);
         }
      }
   }, [normalizeCode, play_blip, play_error, scanned_articles, scan_val]);

   function download() {
      // const ret_data = new Blob(
      //    // const ret_data = new File(
      //    [
      //       JSON.stringify(
      //          Array.from(codes).map((a_code) => {
      //             return {
      //                code: a_code,
      //                qty: 123,
      //             };
      //          }),
      //       ),
      //    ],
      //    // "codes.txt",
      //    {
      //       // type: "text/plain",
      //       type: "application/json",
      //    },
      // );
      // filesaver.saveAs(ret_data, "codes.txt");
      // filesaver.saveAs(ret_data);

      const obj = scanned_articles.reduce((prev, article) => {
         return { ...prev, [article.code]: article.qty };
      }, {});

      fileDownload(
         JSON.stringify(obj),
         `${moment().format("DD_MM_YYYY_HH_mm_ss")}.json`,
         // "application/json",
      );
   }

   const saveToDB = useCallback((list: TArticle[]) => {
      dexie_db.scans.put({
         name: "scans",
         scans: JSON.stringify(list),
      });
   }, []);

   const validateQty = useCallback(() => {
      if (qty > 0) {
         setScannedArticles((cur) => {
            let new_list: TArticle[];

            if (prev_qty === 0) {
               new_list = [{ code: cur_code, qty } as TArticle, ...cur];
            } else {
               new_list = cur
                  .map((a_scan) => {
                     if (a_scan.code === cur_code) {
                        return {
                           code: cur_code,
                           qty: (is_replace_mode ? 0 : a_scan.qty) + qty,
                        };
                     } else {
                        return a_scan;
                     }
                  })
                  .sort((line1, line2) => {
                     if (line1.code === cur_code) {
                        return -1;
                     } else if (line2.code === cur_code) {
                        return 1;
                     } else {
                        return 0;
                     }
                  });
            }
            saveToDB(new_list);
            return new_list;
         });
      }
      showScanner(true);
   }, [cur_code, is_replace_mode, prev_qty, qty, saveToDB, showScanner]);

   const addDigit = useCallback(
      (digit: number) => {
         play_key();
         setUsedPreValue((cur) => {
            if (cur) {
               // Si on a utilisé une valeur pré-remplie (un des boutons pré-remplis). On remplace par le chiffre tappé
               setQty(digit);
            } else {
               // Sinon on fonctionne comme une saisie de calculatrice
               setQty((cur) => cur * 10 + digit);
            }
            return false;
         });
      },
      [play_key],
   );

   const fillWithPreValue = useCallback(
      (new_qty: number) => {
         play_key();
         setQty(new_qty);
         setUsedPreValue(true);
      },
      [play_key],
   );

   // useEffect(() => {
   //    const letters = tmp_scan_val?.split("");

   //    console.log("Lettres=", letters);
   // }, [tmp_scan_val]);

   const onEvt = useCallback(
      (evt: globalThis.KeyboardEvent) => {
         // console.log(evt, is_scanner_visible, evt.key === "Enter");
         if (is_scanner_visible && !is_real_input_field_visible) {
            if (evt.key === "Enter") {
               // setScanVal(tmp_scan_val);
               showKeypad(true);
            } else if (evt.key === "Backspace") {
               setTmpScanVal((cur) =>
                  cur?.length ? cur.substring(0, cur.length - 1) : "",
               );
            } else {
               const letter = evt.key.toLocaleUpperCase();
               if (letter.length === 1) {
                  const digit = [
                     "À",
                     "&",
                     "É",
                     '"',
                     "'",
                     "(",
                     "§",
                     "È",
                     "!",
                     "Ç",
                  ].indexOf(letter);
                  setTmpScanVal(
                     (cur) => `${cur}${digit === -1 ? letter : digit}`,
                  );
               }
            }
         }
      },
      [is_real_input_field_visible, is_scanner_visible, showKeypad],
   );

   useEffect(() => {
      document.addEventListener("keydown", onEvt, false);

      return () => {
         document.removeEventListener("keydown", onEvt, false);
      };
   }, [onEvt]);

   // useEffect(() => {
   //    if (is_scanner_visible) {
   //       ref_scan_input.current?.focus();
   //    }
   // }, [is_scanner_visible]);

   const showRealInputField = useCallback(
      (state: boolean) => {
         setIsRealInputFieldVisible(state);
         if (state) {
            setTimeout(() => {
               ref_scan_input.current?.focus();
            }, 2500);
         } else {
            ref_scan_input.current?.blur();
            showKeypad(true);
         }
      },
      [showKeypad],
   );

   // useEffect(() => {
   //    if (is_real_input_field_visible) {
   //       ref_scan_input.current?.focus();
   //    }
   // }, [is_real_input_field_visible]);

   const changeRealInputField = useCallback((val: string) => {
      setTmpScanVal(val.toUpperCase());
   }, []);

   // useEffect(() => {
   //    setScanVal((val) => {
   //       if (val !== undefined) {
   //          return ;
   //       } else {
   //          return val;
   //       }
   //    });
   // }, [scan_val]);

   const deleteLine = useCallback(
      (code: string) => {
         setScannedArticles((cur) => {
            const new_list = cur.filter((a_line) => a_line.code !== code);

            saveToDB(new_list);
            return new_list;
         });
      },
      [saveToDB],
   );

   console.log("is_keypad_visible", is_keypad_visible);
   console.log("is_scanner_visible", is_scanner_visible);

   return (
      <div className="relative flex h-full grow-0 flex-col items-center justify-start gap-5">
         {/* {is_keypad_visible ? "KPV" : "KPI"}_{is_scanner_visible ? "SV" : "SI"} */}
         <div className={cn("flex h-full w-full flex-col gap-[20px] p-[30px]")}>
            <div
               className={cn(
                  "mx-[-30px] flex h-[110px] gap-5 border-b-[1px] px-[30px] pb-5 drop-shadow-sm",
                  styles.keypad,
               )}
            >
               {!is_scanner_visible && (
                  <PapooButton
                     variant="success"
                     onClick={() => {
                        showScanner(!is_scanner_visible);
                        play_key();
                     }}
                     className="h-full w-[70px] min-w-[70px]"
                     // className="bg-jack-green-500 hover:bg-jack-green-500 active:bg-jack-green-500"
                  >
                     <FontAwesomeIcon
                        icon={faBarcodeRead}
                        className="text-[150%]"
                     />
                  </PapooButton>
               )}
               {is_real_input_field_visible && (
                  <div className="flex w-full gap-2">
                     <PapooInput
                        value={tmp_scan_val}
                        onValueChange={(val) => changeRealInputField(`${val}`)}
                        className="h-full w-full"
                     >
                        <PapooInputField
                           ref={ref_scan_input}
                           autoFocus
                           className="h-full text-right text-[36px] font-bold"
                        />
                     </PapooInput>
                     <PapooButton
                        variant="success"
                        onClick={() => showRealInputField(false)}
                        className="cursor-pointer"
                     >
                        OK
                     </PapooButton>
                  </div>
               )}
               {!is_real_input_field_visible && (
                  <div
                     className="flex basis-full items-center justify-end gap-2 rounded-lg border-4 border-jack-gray-200 px-5 text-[30px] font-extrabold"
                     onClick={() => showRealInputField(true)}
                  >
                     {!is_scanner_visible && (
                        <span className="mr-auto pl-3 text-[18px] text-jack-gray-300">
                           {scan_val}
                        </span>
                     )}
                     <span className="text-jack-gray-300">
                        {!is_scanner_visible && prev_qty && !is_replace_mode
                           ? `${formatNumber(prev_qty)} + `
                           : ""}
                     </span>
                     <span>
                        {is_scanner_visible ? tmp_scan_val : formatNumber(qty)}
                     </span>
                  </div>
               )}
            </div>

            {/* <div id="reader" className="flex w-full"></div>
         {cur_code}
         {got_new_scan && (
            <div className="absolute z-10 flex h-full w-full bg-green-500/50"></div>
         )}
         {got_existing_scan && (
            <div className="absolute z-10 flex h-full w-full bg-red-500/50"></div>
         )}
         <div>
            {scanned_articles.map((a_scan) => {
               return (
                  <span id={a_scan.code}>
                     {a_scan.qty} x {a_scan.code}
                  </span>
               );
            })}
         </div> */}
            {is_scanner_visible && (
               <div className="flex h-full flex-col overflow-auto font-mono text-[28px]">
                  {scanned_articles.map((an_article, index) => {
                     return (
                        <div className="flex items-center py-1">
                           <span key={`article_${index}`}>
                              {an_article.code} x {an_article.qty}
                           </span>
                           <div className="agap-[30px] ml-auto flex items-center">
                              <FontAwesomeIcon
                                 icon={faPen}
                                 className="p-5 text-jack-green-500"
                                 onClick={() => {
                                    showKeypad(true, an_article.code);
                                 }}
                              />
                              <FontAwesomeIcon
                                 icon={faTrash}
                                 className="p-5 text-jack-red-500"
                                 onClick={() => {
                                    deleteLine(an_article.code);
                                 }}
                              />
                           </div>
                        </div>
                     );
                  })}
               </div>
            )}
            {is_keypad_visible && (
               <>
                  <div
                     className={cn(
                        "grid w-full grid-flow-row grid-cols-3 grid-rows-3 gap-[15px]",
                        styles.keypad,
                     )}
                  >
                     <PapooButton onClick={() => addDigit(1)}>1</PapooButton>
                     <PapooButton onClick={() => addDigit(2)}>2</PapooButton>
                     <PapooButton onClick={() => addDigit(3)}>3</PapooButton>
                     <PapooButton onClick={() => addDigit(4)}>4</PapooButton>
                     <PapooButton onClick={() => addDigit(5)}>5</PapooButton>
                     <PapooButton onClick={() => addDigit(6)}>6</PapooButton>
                     <PapooButton onClick={() => addDigit(7)}>7</PapooButton>
                     <PapooButton onClick={() => addDigit(8)}>8</PapooButton>
                     <PapooButton onClick={() => addDigit(9)}>9</PapooButton>
                     <PapooButton
                        onClick={() => {
                           setUsedPreValue((used) => {
                              if (used) {
                                 setQty(0);
                              } else {
                                 setQty((cur) => Math.floor(cur / 10));
                              }
                              return false;
                           });
                           play_key();
                        }}
                        className="bg-red-500 text-white hover:bg-jack-red-700"
                     >
                        <FontAwesomeIcon icon={faDeleteLeft} />
                     </PapooButton>
                     <PapooButton onClick={() => addDigit(0)}>0</PapooButton>
                     <PapooButton
                        onClick={() => {
                           validateQty();
                           play_key();
                        }}
                        className="bg-jack-green-500 text-white hover:bg-jack-green-700"
                     >
                        <FontAwesomeIcon icon={faCheck} />
                     </PapooButton>
                  </div>
                  <div
                     className={cn(
                        "grid w-full grid-flow-row grid-cols-3 grid-rows-3 gap-[15px] border-t-4 border-jack-gray-400 pt-[25px]",
                        styles.keypad,
                     )}
                  >
                     <PapooButton onClick={() => fillWithPreValue(10)}>
                        10
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(15)}>
                        15
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(20)}>
                        20
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(25)}>
                        25
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(30)}>
                        30
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(40)}>
                        40
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(50)}>
                        50
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(75)}>
                        75
                     </PapooButton>
                     <PapooButton onClick={() => fillWithPreValue(100)}>
                        100
                     </PapooButton>
                  </div>
               </>
            )}
            {/* <div className="flex gap-5">
            {is_scanner_visible && (
               <PapooButton onClick={() => showScanner(false)}>
                  Arrêter
               </PapooButton>
            )}
            {!is_scanner_visible && (
               <PapooButton
                  onClick={() => {
                     showScanner(true);
                  }}
               >
                  Scanner
               </PapooButton>
            )}
            {!is_scanner_visible &&
               scanned_articles.length > 0 &&
               !is_keypad_visible && (
                  <PapooButton onClick={() => download()}>
                     Télécharger
                  </PapooButton>
               )}
            {!is_scanner_visible && (
               <PapooButton variant="error" onClick={() => navigate("/")}>
                  Quitter
               </PapooButton>
               )}
               </div> */}
            <div className="mx-[-30px] mt-auto flex justify-evenly gap-5 border-t-[1px] px-[30px] pt-5 drop-shadow-top-sm">
               {/* <div className="apb-[30px] mt-auto flex justify-evenly"> */}
               <PapooButton onClick={() => download()}>Télécharger</PapooButton>
               <PapooButton variant="error" onClick={() => navigate("/")}>
                  Quitter
               </PapooButton>
            </div>
         </div>
      </div>
   );
}

export default Scanner;
