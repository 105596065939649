import { Fragment, useEffect, useState } from "react";
import img_login from "../assets/png/login.webp";
import { Img } from "react-image";
import logo from "../assets/png/logo.png";
import { PapooButton } from "@/components/papoo/PapooButton";
import { useNavigate } from "react-router-dom";
import { registerSW } from "virtual:pwa-register";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faBarcodeRead } from "@fortawesome/pro-solid-svg-icons";
import { db as dexie_db } from "@/stores/db";
import useHelpers from "@/Hooks/useHelpers";

function Main() {
   const navigate = useNavigate();
   // const { apiLogout } = useApi();
   // const { is_logged, is_connected, is_fetching, doLogout } = useContext(
   //    LiveUpdateContext,
   // ) as LiveUpdateContextType;
   const { getVersion, getVersionDate, getCopyright, getDevRefShort } =
      useHelpers();
   const [has_scans, setHasScans] = useState<boolean>();

   useEffect(() => {
      const updateSW = registerSW({
         onRegistered(r) {
            console.log("**SW++ 2 onRegistered", r);
            if (r) {
               r.update(); // On check immédiatement à l'affichage du login
            }
         },
         onNeedRefresh() {
            updateSW();
         },
      });
   }, []);

   useEffect(() => {
      (async () => {
         setHasScans((await dexie_db.scans.toArray()).length > 0);
      })();
   }, []);

   function doPurge() {
      dexie_db.scans.delete("scans");
      setHasScans(false);
   }

   return (
      <Fragment>
         <div className="relative flex h-full w-full flex-col items-center justify-center gap-10">
            <Img
               id="photo-login"
               src={img_login}
               alt="Image login"
               className="absolute h-full w-full rounded-l-lg object-cover opacity-15 saturate-0"
            />
            <Img
               src={logo}
               alt="Logo"
               className="z-10 mx-0 w-1/2 self-center pb-14 pt-10"
            />
            <PapooButton
               variant="success"
               onClick={() => navigate("/scanner")}
               className="z-10 cursor-pointer text-[120%]"
            >
               <FontAwesomeIcon icon={faBarcodeRead} />
               Scanner v{getVersion()}
            </PapooButton>
            <PapooButton
               variant="error"
               disabled={!has_scans}
               onClick={doPurge}
               className="z-10 mt-[30px] cursor-pointer text-[120%]"
            >
               <FontAwesomeIcon icon={faTrash} />
               {has_scans ? "Purger les scans" : "Aucun scan"}
            </PapooButton>
         </div>
         <div className="absolute bottom-0 right-0 flex flex-col items-end pb-5 pr-5 text-jack-gray-300">
            <span>
               {getVersion()} - {getCopyright()} - {getVersionDate()}
            </span>
            <span className="flex items-center gap-2">
               <img
                  src="/png/bigpapoo24.png"
                  alt="Logo"
                  height={24}
                  width={24}
               />
               {getDevRefShort()}
            </span>
         </div>
      </Fragment>
   );
}

export default Main;
